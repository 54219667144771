/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.spheader-row {
    .spheader-column {
        padding: 12px 10px;
        text-align: center;

        h6 {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            margin: 0;
            color: #000000;
        }

        .pheader-price {
            background: var(--theme-color);
            border-radius: 2px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            margin: 0;
            padding: 6px;
            width: 100%;
            max-width: 102px;
            color: #fff;
        }

        .pheader-brand {
            flex: 1;
            // text-align: left;
            text-align: start;
        }
    }
}

.spbody-row {
    .spbody {
        &-column {
            display: flex;
            align-items: center;
            padding: 10px 13px;
        }
    }

    .sproduct {
        &-picture {
            padding: 10px;

            .card-img-top {
                border: 1px solid #e4e4e4;
            }
        }

        &-stock,
        &-artnr,
        &-order {
            justify-content: center;
        }

        &-price {
            margin: 0;
            text-align: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #000;
        }

        &-brandlabel {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            background: #e7e7e7;
            border-radius: 2px;
            padding: 2px 7px;
            color: #000;
        }
    }
}

.spbody-row .spbody-column,
.spheader-row .spheader-column {
    border-right: 1px solid #cfcfcf;
    width: 100%;

    &:last-child {
        border-right: 0px;
    }

    &.sproduct-brand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 5px;
        padding: 6px 19px;
    }

    .pheader-price,
    .sproduct-price {
        flex: 0 0 55%;
    }
    
    h2 {
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    }
}

.sproduct {
    &-header {
        .sproduct-item {
            display: flex;
        }
    }

    &-item {
        display: flex;

        &:nth-child(odd) {
            background-color: #f1f1f1;
        }

        &:nth-child(even) {
            background-color: #ffffff;
        }
    }

    &-picture {
        max-width: 108px;
    }

    &-name {
        max-width: 188px;
    }

    &-brand {
        max-width: 125px;
    }

    &-stock {
        max-width: 67px;
    }

    &-artnr {
        max-width: 80px;
    }

    &-order {
        max-width: 160px;
    }
}

.No-product {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--theme-color);
    min-height: 200px;
}

.notfound-text {
    font-size: 20px;
    color: var(--theme-color);
}

.your-price {
    color: var(--theme-color);
}
